@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.UpperFooter {
    background-color: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Dancing Script', cursive;
    font-size: 20px;
  }
  
  .UpperFooter-container {
    display: flex;
    width: 80%;
    height: 200px;
    flex-wrap: nowrap; 
  }
  
  .UpperFooter-left {
    flex: 1;
    text-align: center;
    margin-top: 70px;
    font-size: clamp(14px, 2vw, 20px); 
    word-wrap: break-word; 
    padding: 0 10px; 
  }
  
  .UpperFooter-separator {
    width: 1px;
    height: 80%;
    background-color: #CCC;
  }
  
  .UpperFooter-right {
    flex: 1;
    text-align: center;
    padding: 0 10px;
    margin-top: 40px;
    font-size: clamp(14px, 2vw, 20px); 
    word-wrap: break-word; 
  }
  
  .UpperFooter-right-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .UpperFooter-right-contact {
    font-weight: bold;
    margin-top: 10px;
  }

  @media (max-width: 767px) {
    .UpperFooter-container {
      flex-direction: column;
      height: auto;
  }
  
  .UpperFooter-separator {
      display: none;
  }
  
  .UpperFooter-left,
  .UpperFooter-right {
      margin-bottom: 20px;
      width: 100%;
  }
  }