.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: env(safe-area-inset-top);
}

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.MainPage {
  flex: 1;
  padding-top: 60px;
}

.UpperFooter, .Footer {
  width: 100%;
}



/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  /* Add styles for small devices */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* Add styles for medium devices */
  body {
    font-size: 14px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Add styles for large devices */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Add styles for extra large devices */
}
  
  